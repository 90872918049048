import React from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { GetButton, TrustBox } from 'components';

const TextContainer = ({
  customSubTextMaxWidth = '24em',
  pathname,
  viewportWidth,
  handleClick,
  title,
  h2Text,
  subText,
  buttonLinkPath,
  buttonText,
  buttonWidth,
  buttonHeight,
  date,
  fees,
  themes
}) => {
  const showGetQuoteButton = fees && Object.values(fees)?.some((fee) => fee?.setupFee);
  console.log(handleClick);
  return (
    <Wrapper pathname={pathname} customSubTextMaxWidth={customSubTextMaxWidth}>
      {date && (
        <h3>
          <Date>{date}</Date> | {themes && <Tags>{themes.join(', ')}</Tags>}
        </h3>
      )}
      {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
      {h2Text && <h2 dangerouslySetInnerHTML={{ __html: h2Text }} />}
      {subText && <p dangerouslySetInnerHTML={{ __html: subText }} />}
      <Buttons>
      {(showGetQuoteButton || buttonLinkPath) && (
        <GetButton
          handleClick={handleClick}
          buttonLinkPath={buttonLinkPath}
          buttonText={buttonText}
          buttonWidth={buttonWidth}
          buttonHeight={buttonHeight}
          viewportWidth={viewportWidth}
          shine
        />
      )}
      {(pathname === '/team-building/' && (
        <GetButton
          handleClick={handleClick}
          buttonLinkPath="https://www.dynamicevents.ie/brochures/dynamic_events_team_building_brochure.pdf"
          buttonText="Team Building Brochure"
          buttonWidth="14.5rem"
          buttonHeight={buttonHeight}
          viewportWidth={viewportWidth}
          shine
        />
      ))}
      {(pathname === '/team-training/' && (
        <GetButton
          handleClick={handleClick}
          buttonLinkPath="https://www.dynamicevents.ie/brochures/dynamic_events_team_training_brochure.pdf"
          buttonText="Team Training Brochure"
          buttonWidth="14.5rem"
          buttonHeight={buttonHeight}
          viewportWidth={viewportWidth}
          shine
        />
      ))}
      {(pathname === '/event-management/' && (
        <GetButton
          handleClick={handleClick}
          buttonLinkPath="https://www.dynamicevents.ie/brochures/dynamic_events_event_management_brochure.pdf"
          buttonText="Event Management Brochure"
          buttonWidth="17rem"
          buttonHeight={buttonHeight}
          viewportWidth={viewportWidth}
          shine
        />
      ))}
      </Buttons>
      <TrustBox
        style={{ marginTop: '20px' }}
        data={{
          'data-locale': 'en-US',
          'data-template-id': '5406e65db0d04a09e042d5fc',
          'data-businessunit-id': '601a87b799da2200016d6fbd',
          'data-style-height': '28px',
          'data-style-width': '100%',
          'data-theme': 'dark'
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  bottom: 3.25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 4.5vw;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1199px) {
    left: initial;
  }

  @media screen and (min-width: 1366px) {
    bottom: 3.25%;
  }

  @media screen and (min-width: 1441px) {
    left: 4.5vw;
    max-width: 100%;
  }

  @media screen and (min-width: 500px) {
    ${({ pathname }) =>
      pathname === '/' &&
      css`
        bottom: 3%;
      `}
  }

  h1,
  h2,
  h3,
  p,
  span {
    letter-spacing: 0.01125em;
  }

  h2,
  h3,
  p {
    color: #fff;
    font-family: 'proxima-nova', sans-serif;
  }

  h1 {
    color: var(--dynamic-blue);
    font-family: adelle, serif;
    font-size: calc(1.5rem + 1.8vw);
    font-weight: 700;
    max-width: 87.5vw;

    @media screen and (min-width: 1143px) {
      font-size: calc(1.25rem + 1.8vw);
    }

    @media screen and (min-width: 1200px) {
      max-width: 60vw;
    }

    @media screen and (min-width: 2560px) {
      max-width: 80rem;
    }
  }

  h2 {
    font-size: calc(0.85rem + 1vw);
    font-style: normal;
    font-weight: 500;
    margin-top: 0.3em;
    margin-bottom: 0.5em;
  }

  h3 {
    font-size: calc(0.72rem + 0.5vw);
    font-weight: 400;
    margin-right: 3em;
    margin-bottom: 0.225em;
    max-width: 87.5vw;

    @media screen and (min-width: 440px) {
      max-width: 34em;
    }
  }

  p {
    font-size: calc(0.85rem + 0.725vw);
    font-weight: 300;
    max-width: 87.5vw;
    margin-top: 0.75em;
    margin-bottom: 0.5em;

    a {
      color: #fff;
      text-decoration: underline;
    }

    @media screen and (min-width: 440px) {
      max-width: ${({ customSubTextMaxWidth }) => customSubTextMaxWidth};
      padding-right: 3rem;
    }
  }

  a {
    text-decoration: none;
  }

  @media screen and (min-width: 2560px) {
    bottom: 99px;
    left: 302px;

    h1 {
      font-size: 66px;
    }

    h2 {
      font-size: 39px;
    }

    h3 {
      font-size: 24.32px;
    }

    p {
      font-size: 33.4px;
    }
  }
`;

const Date = styled.span`
  color: var(--dynamic-blue);
  font-weight: 500;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (min-width: 500px) {
    flex-direction: row;
  }
`;

const Tags = styled.span``;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(TextContainer);
