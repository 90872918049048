import React, { useState, useEffect } from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { sortByGridOrder } from 'utils';
import qs from 'qs';
import { InView } from 'react-intersection-observer';
import { Cards as VenuePartnersCards, VenuePartnersFilters, GetButton } from 'components';
import BlobYellowRed from 'images/team_building/misc/blob_yellow_red.svg';
import BlobBluePurple from 'images/team_building/misc/blob_blue_purple.svg';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_3.svg';
import getFiltersInitialState from '../../TeamBuilding/FiltersAndCards/getFiltersInitialState';
import getCorrespondingFilterCategoryState from './getCorrespondingFilterCategoryState';

const VenuePartnersFiltersAndCards = ({
  venues,
  gridOrder,
  pathname,
  viewportWidth,
  parameters
}) => {
  const allCards = gridOrder ? sortByGridOrder(venues, gridOrder) : activities.edges;
  const [showTheseCards, setShowTheseCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [clearedFilters, setClearedFilters] = useState({});

  const countiesDividedByRegion = {
    north: [
      'Argmagh',
      'Antrim',
      'Cavan',
      'Derry',
      'Donegal',
      'Down',
      'Fermanagh',
      'Monaghan',
      'Tyrone'
    ],
    east: ['Carlow', 'Dublin', 'Kildare', 'Kilkenny', 'Louth', 'Meath', 'Wexford', 'Wicklow'],
    midlands: ['Laois', 'Longford', 'Offaly', 'Roscommon', 'Westmeath'],
    south: ['Clare', 'Cork', 'Kerry', 'Limerick', 'Tipperary', 'Waterford'],
    west: ['Galway', 'Leitrim', 'Mayo', 'Sligo']
  };

  const filterCategories = venues.edges.map(({ node }) => {
    const { county } = node.childMarkdownRemark.frontmatter;
    const region = county.map((place) => {
      switch (true) {
        case countiesDividedByRegion.north.includes(place):
          return 'North';
        case countiesDividedByRegion.east.includes(place):
          return 'East';
        case countiesDividedByRegion.midlands.includes(place):
          return 'Midlands';
        case countiesDividedByRegion.south.includes(place):
          return 'South';
        case countiesDividedByRegion.west.includes(place):
          return 'West';
      }
    });
    return {
      county: county === null ? [] : county,
      region: region
    };
  });

  const [animateLightBlueBackgroundSVG, setAnimateLightBlueBackgroundSVG] = useState(false);

  const [filterValues, setFilterValues] = useState(getFiltersInitialState(filterCategories));

  useEffect(() => {
    const _checkedFilterValues = Object.entries(filterValues).reduce((acc, [filterTitle, nestedObj]) => {
      acc[filterTitle] = Object.entries(nestedObj).slice(1).reduce((_acc, [prop, value]) => {
        if(value === true) {
          _acc[prop] = value;
        }
        return _acc;
      }, {});
      return acc;
    }, {});
    const queryString = qs.stringify(_checkedFilterValues);
    window.history.replaceState(null, null, `?${queryString}`);
  }, [filterValues]);

  const filterCards = (card) => {
    const { county } = card.node.childMarkdownRemark.frontmatter;
    const region = county.map((place) => {
      switch (true) {
        case countiesDividedByRegion.north.includes(place):
          return 'North';
        case countiesDividedByRegion.east.includes(place):
          return 'East';
        case countiesDividedByRegion.midlands.includes(place):
          return 'Midlands';
        case countiesDividedByRegion.south.includes(place):
          return 'South';
        case countiesDividedByRegion.west.includes(place):
          return 'West';
      }
    });
    const countyArray = county === null ? [] : county;
    const getCurrentlySelectedFiltersInThisCategory = (filters) =>
      Object.entries(filters)
        .slice(1)
        .filter((filter) => filter[1])
        .map((filter) => filter[0]);

    const condition1 =
      filterValues.county['All Counties'] ||
      countyArray.some((name) =>
        getCurrentlySelectedFiltersInThisCategory(filterValues.county).includes(name)
      );

    const condition2 =
      filterValues.region['All Regions'] ||
      region.some((place) =>
        getCurrentlySelectedFiltersInThisCategory(filterValues.region).includes(place)
      );

    if (condition1 && condition2) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const clearedCountyFilters = Object.keys(filterValues.county)
      .slice(1)
      .reduce((acc, filter) => {
        acc[filter] = false;
        return acc;
      }, {});

    const clearedRegionFilters = Object.keys(filterValues.region)
      .slice(1)
      .reduce((acc, filter) => {
        acc[filter] = false;
        return acc;
      }, {});

    setClearedFilters({
      ['county']: {
        ['All Counties']: true,
        ...clearedCountyFilters
      },
      ['region']: {
        ['All Regions']: true,
        ...clearedRegionFilters
      }
    });
  }, [filterValues]);

  useEffect(() => {
    const filteredCards = allCards.filter(filterCards);

    if (searchQuery === '') {
      setShowTheseCards(filteredCards.length ? filteredCards : []);
    } else if (searchQuery !== '') {
      const searchFilteredCards = filteredCards.filter(
        (card) =>
          card.node.childMarkdownRemark.frontmatter.title
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          card.node.childMarkdownRemark.frontmatter.county.some((location) =>
            location.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          card.node.childMarkdownRemark.frontmatter.summary
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
      setShowTheseCards(searchFilteredCards);
    }
  }, [filterValues, searchQuery]);

  const handleSearchInput = ({ target }) => {
    const { value } = target;
    setSearchQuery(value);
  };

  const handleFilterClick = ({ e, filterCategory, i }) => {
    // This logic checks which (if any) filters are ticked in this filter category and
    // toggles that category's allFilter (e.g. 'All Format/Support Options', 'All Locations', etc.)
    // accordingly.
    const { target } = e;
    const allFilter = Object.keys(filterValues[filterCategory])[0];
    let countyOtherFilters;
    let regionOtherFilters;
    if (i === 0) {
      // Set all filters back to all
      setFilterValues(clearedFilters);
    } else if (i !== 0) {
      let regionCorrespondingFilterCategoryState = null;
      let countyCorrespondingFilterCategoryState = null;
      let filterCategoryValues = null;
      let otherFilterCategoryValues = null;

      if (filterCategory === 'region') {
        filterCategoryValues = filterValues.county;
        otherFilterCategoryValues = filterValues.region;
        regionCorrespondingFilterCategoryState = getCorrespondingFilterCategoryState({
          filterCategoryValues,
          otherFilterCategoryValues,
          filterCategory,
          countiesDividedByRegion,
          target
        });
      }

      if (filterCategory === 'county') {
        filterCategoryValues = filterValues.region;
        otherFilterCategoryValues = filterValues.county;
        countyCorrespondingFilterCategoryState = getCorrespondingFilterCategoryState({
          filterCategoryValues,
          otherFilterCategoryValues,
          filterCategory,
          countiesDividedByRegion,
          target
        });
      }

      setFilterValues((currentValues) => {
        // Set the allFilter to false if any of the other filters in this category are selected.
        const newFilterCategoryState = {
          ...currentValues[filterCategory],
          [allFilter]: false,
          [e.target.name]: target.checked
        };

        // If no filters are selected in this category, then select that category's allFilter.
        const allFiltersDeselected = Object.values(newFilterCategoryState)
          .slice(1)
          .every((boolean) => !boolean);

        if (allFiltersDeselected) {
          return clearedFilters;
        }

        if (regionCorrespondingFilterCategoryState) {
          return {
            ['county']: regionCorrespondingFilterCategoryState,
            [filterCategory]: newFilterCategoryState
          };
        }

        if (countyCorrespondingFilterCategoryState) {
          return {
            [filterCategory]: newFilterCategoryState,
            ['region']: countyCorrespondingFilterCategoryState
          };
        }

        return {
          ...currentValues,
          [filterCategory]: newFilterCategoryState
        };
      });
    }
  };

  useEffect(() => {
    if (Object.keys(parameters).length > 0) {
      Object.keys(parameters).forEach((parameter) => {
        if (filterValues[parameter][parameters[parameter]] !== undefined) {
          let regionCorrespondingFilterCategoryState = null;
          let countyCorrespondingFilterCategoryState = null;
          let filterCategoryValues = null;
          let otherFilterCategoryValues = null;

          const allFilter = Object.keys(filterValues[parameter])[0];
          const selectedFilter = parameters[parameter];
          const otherFilters = Object.keys(filterValues[parameter])
            .slice(1)
            .reduce((acc, filter) => {
              acc[filter] = false;
              return acc;
            }, {});

          if (parameter === 'region') {
            filterCategoryValues = filterValues.county;
            otherFilterCategoryValues = filterValues.region;
            regionCorrespondingFilterCategoryState = getCorrespondingFilterCategoryState({
              filterCategoryValues,
              otherFilterCategoryValues,
              filterCategory: 'region',
              countiesDividedByRegion,
              target: {
                checked: true,
                name: selectedFilter[0]
              }
            });

            setFilterValues(() => ({
              ['county']: regionCorrespondingFilterCategoryState,
              [parameter]: {
                [allFilter]: false,
                ...otherFilters,
                [selectedFilter]: true
              }
            }));
          }

          if (parameter === 'county') {
            filterCategoryValues = filterValues.region;
            otherFilterCategoryValues = filterValues.county;
            countyCorrespondingFilterCategoryState = getCorrespondingFilterCategoryState({
              filterCategoryValues,
              otherFilterCategoryValues,
              filterCategory: 'county',
              countiesDividedByRegion,
              target: {
                checked: true,
                name: selectedFilter[0]
              }
            });

            setFilterValues(() => ({
              ['region']: countyCorrespondingFilterCategoryState,
              [parameter]: {
                [allFilter]: false,
                ...otherFilters,
                [selectedFilter]: true
              }
            }));
          }
        }
      });
    }
  }, [parameters]);

  return (
    <Wrapper>
      <LightBlueBackground
        animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
        src={LightBlueBackgroundSVG}
        alt="Blue blob"
        animationDuration="0.75s"
      />
      {viewportWidth >= 400 && (
        <StyledBlobYellowRed src={BlobYellowRed} alt="yellow and red blob" />
      )}
      {viewportWidth >= 400 && (
        <StyledBlobBluePurple src={BlobBluePurple} alt="blue and purple blob" />
      )}
      <InView onChange={setAnimateLightBlueBackgroundSVG} triggerOnce>
        <FlexWrapper>
          {venues && (
            <>
              <VenuePartnersFilters
                filterCategories={filterCategories}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                searchQuery={searchQuery}
                handleFilterClick={handleFilterClick}
                handleSearchInput={handleSearchInput}
              />
              <VenuePartnersCards showTheseCards={showTheseCards} pathname={pathname} />
            </>
          )}
        </FlexWrapper>
      </InView>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding: 1.85rem 1rem 0;

  @media screen and (min-width: 75rem) {
    padding: 6.063rem 1rem 1.45rem;
  }

  @media screen and (min-width: 90rem) {
    padding: 6.063rem 0rem 1.45rem;
  }
`;

const FlexWrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  margin: 0 auto;

  @media screen and (min-width: 54.375rem) {
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledBlobYellowRed = styled.img`
  position: absolute;
  top: 240px;
  left: 0;
`;

const StyledBlobBluePurple = styled.img`
  position: absolute;
  top: 960px;
  right: 0;
`;

const LightBlueBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: 30px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  VenuePartnersFiltersAndCards
);
